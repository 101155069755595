export default {
  methods: {
    onPhoneInput(value) {
      const { phoneMask, phonePrefix } = this
      const { VMask } = this.$options.filters

      const phoneMaskLength = phoneMask?.toString().replace(/[^#]/g, '').length
      const phonePrefixLength = phonePrefix
        ?.toString()
        .replace(/\D+/g, '').length

      let number = value.replace(/\D+/g, '')
      
      if (
        number.length > phoneMaskLength + phonePrefixLength &&
        (number[phonePrefixLength] === '7' || number[phonePrefixLength] === '8')
      ) {
        number = number.substring(1 + phonePrefixLength)
        this.$nextTick(() => {
          this.phone = VMask(number, phoneMask)
        })
      }
    },
  },
}

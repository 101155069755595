<template>
  <div class="text-center">
    <template v-if="stage === 1">
      <div class="heading text-center dark--text mb-2">Вход</div>

      <div class="mb-4">Введите ваш номер телефона</div>

      <v-text-field
        key="phone"
        v-model="phone"
        v-mask="phoneMask"
        class="mb-4"
        label="Телефон"
        type="tel"
        outlined
        hide-details
        :rules="[(v) => !!v]"
        @input="onPhoneInput"
      />
      <div v-if="timeLeft > 0" class="mb-3 text-center primary--text">
        Получить новый код можно через
        {{ timeLeft }} сек.
      </div>
    </template>

    <template v-else-if="stage === 2">
      <template v-if="useReactiveSmsGateway">
        <ReactiveSmsGatewayContext :code.sync="code" />
      </template>
      <template v-else>
        <div class="mb-3">
          <div class="mb-1">
            На номер <b>{{ phone }}</b> отправлен код подтверждения
          </div>
          <div>Введите его в поле ниже</div>
        </div>

        <v-text-field
          key="code"
          v-model="code"
          class="mb-4"
          label="Код"
          outlined
          hide-details
          :rules="[(v) => !!v]"
        />
      </template>

      <div>
        <a href="#" class="error--text" @click.prevent="stage = 1">
          Вернуться назад
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import ReactiveSmsGatewayContext from '@/components/context/ReactiveSmsGatewayContext'

import actionButtonMixin from '@/mixins/actionButton'
import timerMixin from '@/mixins/timerMixin'
import authMixin from '@/mixins/authMixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [actionButtonMixin, timerMixin, authMixin],
  components: {
    ReactiveSmsGatewayContext,
  },
  data: () => ({
    loading: false,
    stage: 1,
    phone: '',
    code: '',
  }),

  computed: {
    actionButton() {
      if (this.stage === 1) {
        return {
          active: true,
          text: 'Отправить код',
          action: this.sendSms,
          disabled: this.timeLeft > 0,
        }
      } else if (this.stage === 2) {
        return {
          active: true,
          text: 'Подтвердить',
          action: this.login,
        }
      } else {
        return {
          active: false,
        }
      }
    },

    ...mapGetters('app', ['phoneMask', 'phonePrefix', 'useReactiveSmsGateway']),
  },

  methods: {
    async sendSms() {
      try {
        this.loading = true

        const response = await this.$api.get('sendconfirmationcode', {
          params: {
            phone: this.phone,
          },
          headers: {
            ...(this.$store.getters['app/recaptchaEnabled'] && {
              authorization: await this.$recaptcha(),
            }),
          },
        })

        if (response.code !== 1) {
          throw new Error(response.msg || 'Не удалось отправить смс')
        }
        this.commitTime()
        this.stage = 2
      } catch (error) {
        this.$store.commit('snackbar/update', {
          active: true,
          text: error.message,
        })
      } finally {
        this.loading = false
      }
    },

    async login() {
      try {
        this.loading = true

        const response = await this.$api.get('loginbyphoneandcode', {
          params: {
            phone: this.phone,
            code: this.code,
          },
        })

        const token = response.details.token

        if (response.code !== 1) {
          throw new Error(response.msg || 'Не удалось авторизоваться')
        }

        if (!token) {
          throw new Error('Не удалось авторизоваться')
        }

        const result = this.$store.dispatch('user/checkToken', token)
        if (result) {
          this.$tracardi.track('auth', {
            phone: this.phone?.replace(/\D+/g, ''),
          })
          this.$router.push({ name: this.$route.query.to || 'Home' })
        } else {
          throw new Error('Не удалось авторизоваться')
        }

        this.phone = ''
        this.code = ''
      } catch (error) {
        this.$store.commit('snackbar/update', {
          active: true,
          text: error.message,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

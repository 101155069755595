/**
 * Range factory for initializing array.
 *
 * @export
 * @param  {number} length
 * @param  {Tuple} [fillValue='']
 * @return
 */
export function range(length, fillValue = '') {
  return Array.apply(null, { length })
    .fill(fillValue)
    .map((_, index) => index)
}

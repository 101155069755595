<template>
  <v-container class="py-7">
    <AlternativeAuthForm v-if="$store.getters['app/alternativeAuth']" />
    <AuthForm v-else />
  </v-container>
</template>

<script>
import AuthForm from "@/components/AuthForm";
import AlternativeAuthForm from "@/components/AlternativeAuthForm";

export default {
  components: {
    AuthForm,
    AlternativeAuthForm,
  },
};
</script>
